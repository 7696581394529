import { Subject, filter, BehaviorSubject } from 'rxjs';
import { ofType, isJSType, fillObject, isNotNullish, getDataFromDB, selectObjectProps, isEmptyObject } from '@noda-lib/utils/handlers';
import { takeUntil, filter as filter$1 } from 'rxjs/operators';
import { signal } from '@angular/core';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class ActionsManager {
  constructor() {
    this.__action$ = new Subject();
  }
  init(serviceInstance) {
    serviceInstance.__action$ = new Subject();
  }
  action(stateAction) {
    this.__action$.next(stateAction);
  }
  actions(stateActions) {
    stateActions.forEach(action => this.action(action));
  }
  listenActions(actions) {
    if (actions) {
      return this.__action$.asObservable().pipe(filter(action => actions.includes(action?.type)));
    }
    return this.__action$.asObservable();
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class DetectManager {
  constructor() {
    this.__detector$ = new Subject();
  }
  init(serviceInstance) {
    serviceInstance.__detector$ = new Subject();
  }
  detectChanges(type = 'default') {
    this.__detector$.next(type);
  }
  listenDetectChanges() {
    return this.__detector$.asObservable();
  }
}
class RequestError {
  constructor(error, type, payload = null) {
    this.error = error;
    this.type = type;
    this.payload = payload;
  }
}
class ErrorManager {
  constructor() {
    this.__error$ = new Subject();
  }
  init(serviceInstance) {
    serviceInstance.__error$ = new Subject();
  }
  error(error, type, payload) {
    this.__error$.next(new RequestError(error, type, payload));
  }
  listenErrors(errors) {
    if (errors) {
      return this.__error$.asObservable().pipe(filter(error => {
        const errorByType = errors.find(type => `error-${type}` === error.type);
        return Boolean(errorByType);
      }));
    }
    return this.__error$.asObservable();
  }
  onError(type, errorCb, payload) {
    return e => {
      if (Boolean(errorCb)) errorCb(e);
      this.error(e, type, payload);
    };
  }
  /**
   * @deprecated use only onError
   */
  detectError(action, errorCb, payload) {
    return e => {
      if (Boolean(errorCb)) errorCb(e);
      this.error(e, action, payload);
    };
  }
}
class StreamsManager {
  getSubject(streamName) {
    const stream$ = this[streamName];
    if (!stream$) throw new Error(`Invalid stream name: "${streamName}"`);
    return this[streamName];
  }
  getStream(streamName) {
    return this.getSubject(streamName).asObservable();
  }
  getStreamValue(streamName) {
    const sub$ = this.getSubject(streamName);
    if (sub$ instanceof BehaviorSubject) {
      return sub$.getValue();
    }
    throw new Error(`${streamName} is not an instance of the class: BehaviorSubject`);
  }
  emitToStream(streamName, value) {
    const stream$ = this.getSubject(streamName);
    stream$.next(value);
  }
  resetStreams(streamsData) {
    streamsData.forEach(stream => {
      if (typeof stream === 'string') {
        this[stream].next(null);
      } else {
        const {
          streamName,
          streamValue
        } = stream;
        if (!this[streamName]) {
          throw new Error(`Stream ${streamName} is not defined`);
        }
        this[streamName].next(streamValue);
      }
    });
  }
  /**
   * @deprecated use only resetStreams
   */
  destroyStreams(streamsData) {
    streamsData.forEach(stream => {
      if (typeof stream === 'string') {
        this[stream].next(null);
      } else {
        const {
          streamName,
          streamValue
        } = stream;
        if (!this[streamName]) {
          throw new Error(`Stream ${streamName} is not defined`);
        }
        this[streamName].next(streamValue);
      }
    });
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class UnsubscribeManager {
  constructor() {
    this.__unsubscribe$ = new Subject();
    this.__subTypes = [];
  }
  init(serviceInstance) {
    serviceInstance.__unsubscribe$ = new Subject();
    serviceInstance.__subTypes = [];
  }
  untilDestroyed() {
    return takeUntil(this.__unsubscribe$.asObservable().pipe(filter$1(v => {
      return !v;
    })));
  }
  untilDestroyedByType(type) {
    this.__subTypes.push(type);
    return takeUntil(this.__unsubscribe$.asObservable().pipe(ofType(type)));
  }
  unsubscribe() {
    this.__unsubscribe$.next(void 0);
  }
  unsubscribeByTypes(subTypes) {
    subTypes.forEach(key => {
      this.__unsubscribe$.next(key);
      const idx = this.__subTypes.indexOf(key);
      if (~idx) this.__subTypes.splice(idx, 1);
    });
  }
  fullUnsubscribe() {
    this.unsubscribe();
    this.unsubscribeByTypes(this.__subTypes.concat());
  }
}
const SERVICE_MANAGERS_MAP = {
  unsubscribe: UnsubscribeManager,
  actions: ActionsManager,
  error: ErrorManager,
  detect: DetectManager,
  streams: StreamsManager
};
const FULL_MANAGERS = ['actions', 'detect', 'error', 'streams', 'unsubscribe'];
function makeEnumerableProto(instanceProto) {
  const descriptors = Object.getOwnPropertyDescriptors(instanceProto);
  return Object.entries(descriptors).reduce((accum, [key, descriptor]) => {
    const isFunctionalMethod = key !== 'constructor' && key !== 'init' && isJSType(descriptor.value, 'function');
    if (isFunctionalMethod) Object.assign(accum, {
      [key]: descriptor.value
    });
    return accum;
  }, {});
}
function UseManagers(managers) {
  return managers;
}
function createServiceManagersInstances(managers) {
  return managers.map(managerKey => new SERVICE_MANAGERS_MAP[managerKey]());
}
function createServiceManagersPrototype(instances) {
  const prototype = instances.reduce((accum, instance) => {
    const instanceProto = Object.getPrototypeOf(instance);
    const enumerableInstanceProto = makeEnumerableProto(instanceProto);
    return Object.assign(accum, enumerableInstanceProto);
  }, {});
  return prototype;
}
function ServiceManager(managers = FULL_MANAGERS) {
  const instances = createServiceManagersInstances(managers);
  const mergedManagersProto = createServiceManagersPrototype(instances);
  const __ServiceController = class {
    constructor(managersInstances) {
      this.managersInstances = managersInstances;
    }
    __initController__(serviceInstance) {
      this.managersInstances.forEach(instance => instance.init?.(serviceInstance));
    }
  };
  Object.assign(__ServiceController.prototype, mergedManagersProto);
  return new __ServiceController(instances);
}
/**
 * @deprecated Use only ServiceManager
 */
function StreamManager(managers = FULL_MANAGERS) {
  const instances = createServiceManagersInstances(managers);
  const mergedManagersProto = createServiceManagersPrototype(instances);
  const __ServiceController = class {
    constructor(managersInstances) {
      this.managersInstances = managersInstances;
    }
    __initController__(serviceInstance) {
      this.managersInstances.forEach(instance => instance.init?.(serviceInstance));
    }
  };
  Object.assign(__ServiceController.prototype, mergedManagersProto);
  return new __ServiceController(instances);
}
class ServiceAction {
  constructor(type, payload = null) {
    this.type = type;
    this.payload = payload;
  }
}

/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
/* eslint-disable @typescript-eslint/naming-convention */
class SignalStateWorker {
  constructor(State) {
    this.State = State;
    this.currentState = null;
    this.initialize(State);
  }
  initialize(State) {
    const instance = Object.entries(new State()).reduce((accum, [key, value]) => {
      accum[key] = signal(value);
      return accum;
    }, {});
    this.currentState = instance;
  }
  getFullState() {
    return this.currentState;
  }
  get(key) {
    return this.currentState[key]();
  }
  set(key, value) {
    this.currentState[key].set(value);
  }
  select(key) {
    return this.currentState[key].asReadonly();
  }
  patch(partialObject) {
    Object.entries(partialObject).forEach(([key, value]) => {
      this.set(key, value);
    });
  }
  resetState(fields, options = {
    nonNullable: true
  }) {
    const instance = options.nonNullable ? new this.State() : fillObject(new this.State(), null);
    fields.forEach(field => {
      this.set(field, instance[field]);
    });
  }
  reset(options) {
    const stateKeys = Object.keys(this.currentState);
    this.resetState(stateKeys, options);
  }
  resetFields(fields, options) {
    this.resetState(fields, options);
  }
}

/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
class SignalStateManager {
  constructor(signalStates) {
    this.signalStates = signalStates;
  }
  init(serviceInstance) {
    serviceInstance._signalStateWorkers = Object.entries(this.signalStates).reduce((accum, [type, State]) => {
      accum[type] = new SignalStateWorker(State);
      return accum;
    }, {});
  }
  getFullSignalState(type) {
    return this._signalStateWorkers[type].getFullState();
  }
  getSignalState(type, key) {
    return this._signalStateWorkers[type].get(key);
  }
  hasSignalStateValue(type, key) {
    const value = this.getSignalState(type, key);
    return isNotNullish(value);
  }
  setSignalState(type, key, value) {
    this._signalStateWorkers[type].set(key, value);
  }
  selectSignalState(type, key) {
    return this._signalStateWorkers[type].select(key);
  }
  patchSignalState(type, partialState) {
    this._signalStateWorkers[type].patch(partialState);
  }
  resetSignalStates(types, options) {
    types.forEach(type => this._signalStateWorkers[type].reset(options));
  }
  resetSignalStateFields(type, fields, options) {
    this._signalStateWorkers[type].resetFields(fields, options);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
function SignalState(states) {
  const instance = new SignalStateManager(states);
  const managerPrototype = makeEnumerableProto(Object.getPrototypeOf(instance));
  const __SignalStateController = class {
    constructor(stateManager) {
      this.stateManager = stateManager;
    }
    __initController__(serviceInstance) {
      this.stateManager.init(serviceInstance);
    }
  };
  Object.assign(__SignalStateController.prototype, managerPrototype);
  return new __SignalStateController(instance);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
class DatabaseManager {
  constructor(__db__) {
    this.__db__ = __db__;
  }
  init(serviceInstance) {
    serviceInstance.__db__ = this.__db__;
  }
  getDataFromDB(keys, isCopy) {
    return getDataFromDB(this.__db__, keys, isCopy);
  }
}
class StateChange {
  constructor(property, previous, current) {
    this.property = property;
    this.previous = previous;
    this.current = current;
  }
}
class StateWorker {
  constructor(StateConstructor) {
    this.StateConstructor = StateConstructor;
    this.change$ = new Subject();
    this.initialize(this.defaultInstance);
  }
  initialize(state) {
    this.currentState = state;
  }
  get defaultInstance() {
    return new this.StateConstructor();
  }
  get(key) {
    return this.currentState[key];
  }
  getFull() {
    return this.currentState;
  }
  set(key, value) {
    const previous = this.get(key);
    this.currentState[key] = value;
    this.next(key, previous, value);
  }
  patch(partialObject) {
    Object.assign(this.currentState, partialObject);
  }
  reset() {
    this.currentState = Object.assign(this.currentState, this.defaultInstance);
  }
  resetFields(fields) {
    const resetFieldsObject = selectObjectProps(this.defaultInstance, fields);
    Object.assign(this.currentState, resetFieldsObject);
  }
  next(property, previous, current) {
    const change = new StateChange(property, previous, current);
    this.change$.next(change);
  }
  listen() {
    return this.change$.asObservable();
  }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
class StatesManager {
  constructor(states) {
    this.states = states;
    this._stateWorkers = null;
  }
  init(serviceInstance) {
    serviceInstance._stateWorkers = Object.entries(this.states).reduce((accum, [stateKey, StateInstance]) => {
      accum[stateKey] = new StateWorker(StateInstance);
      return accum;
    }, {});
  }
  getFullState(managerType) {
    return this._stateWorkers[managerType].getFull();
  }
  getState(managerType, prop) {
    return this._stateWorkers[managerType].get(prop);
  }
  setState(managerType, prop, value) {
    this._stateWorkers[managerType].set(prop, value);
  }
  patchState(managerType, partialObject) {
    this._stateWorkers[managerType].patch(partialObject);
  }
  resetStates(managerTypes) {
    managerTypes.forEach(managerType => {
      this._stateWorkers[managerType]?.reset();
    });
  }
  resetStateFields(managerType, fields) {
    this._stateWorkers[managerType].resetFields(fields);
  }
  /**
   * @deprecated Use only resetStates
   */
  destroyStates(...managerTypes) {
    managerTypes.forEach(managerType => {
      this._stateWorkers[managerType]?.reset();
    });
  }
  /**
   * @deprecated use only listenStateChanges
   */
  stateChanges(managerType, filterProps) {
    return this._stateWorkers[managerType]?.listen().pipe(filter(({
      property
    }) => {
      if (filterProps) return filterProps.includes(property);
      return true;
    }));
  }
  listenStateChanges(managerType, filterProps) {
    return this._stateWorkers[managerType]?.listen().pipe(filter(({
      property
    }) => {
      return filterProps.includes(property);
    }));
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
function assignWithStatesManager(statesObject, instances) {
  const states = selectObjectProps(statesObject, ['flags', 'state']);
  if (!isEmptyObject(states)) {
    const instance = new StatesManager(states);
    const proto = Object.getPrototypeOf(instance);
    const enumerablePrototype = makeEnumerableProto(proto);
    instances.push({
      instance,
      enumerablePrototype
    });
  }
}
function assignWithDBManager(statesObject, instances) {
  const {
    db
  } = selectObjectProps(statesObject, ['db']);
  if (Boolean(db)) {
    const instance = new DatabaseManager(db);
    const proto = Object.getPrototypeOf(instance);
    const enumerablePrototype = makeEnumerableProto(proto);
    instances.push({
      instance,
      enumerablePrototype
    });
  }
}
function State(statesObject) {
  const instances = [];
  assignWithStatesManager(statesObject, instances);
  assignWithDBManager(statesObject, instances);
  const __StateController = class {
    constructor(stateManagers) {
      this.stateManagers = stateManagers;
    }
    __initController__(serviceInstance) {
      this.stateManagers.forEach(({
        instance
      }) => instance.init?.(serviceInstance));
    }
  };
  instances.forEach(({
    enumerablePrototype
  }) => Object.assign(__StateController.prototype, enumerablePrototype));
  return new __StateController(instances);
}
function ExtendsFactory(...mixins) {
  const mergedExtends = mixins.reduce((accum, instance) => {
    const prototype = Object.getPrototypeOf(instance);
    return Object.assign(accum, prototype);
  }, {});
  const __FactoryExtender = class {
    constructor() {
      this._initializeFactory();
    }
    _initializeFactory() {
      mixins.forEach(instance => instance.__initController__(this));
    }
  };
  Object.assign(__FactoryExtender.prototype, mergedExtends);
  return __FactoryExtender;
}

/**
 * Generated bundle index. Do not edit.
 */

export { ActionsManager, ExtendsFactory, FULL_MANAGERS, RequestError, ServiceAction, ServiceManager, SignalState, State, StateChange, StreamManager, UnsubscribeManager, UseManagers };
